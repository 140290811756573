import React from "react";
import "./Content.css";
import { Box, Button, Grommet, Heading } from "grommet";
import { Github, Linkedin, Mail } from "grommet-icons";
import { TPPI } from "../../assets/icons/Tppi.svg";

function Content(props: any) {
  return (
    <Box
      align="center"
      justify="center"
      background="brand"
      pad="xlarge"
      fill={true}
      flex={true}
    >
      <Heading>Jarrett Andrulis</Heading>
      <Button
        icon={<TPPI />}
        hoverIndicator
        margin="xsmall"
        label="My Projects"
        href="https://testpagepleaseignore.com"
      ></Button>
      <Box direction="row">
        <Button
          icon={<Github />}
          hoverIndicator
          margin="small"
          href="https://github.com/jarrettandrulis"
        />
        <Button
          icon={<Linkedin />}
          hoverIndicator
          margin="small"
          href="https://linkedin.com/in/jarrettandrulis"
        ></Button>
        <Button
          icon={<Mail />}
          hoverIndicator
          margin="small"
          href="mailto:jarrettandrulis@gmail.com"
        ></Button>
      </Box>
    </Box>
  );
}

export default Content;
