import React from 'react';
import { Blank, IconProps } from 'grommet-icons';

export const TPPI = (props:any) => (
<Blank {...props}>
<svg
   viewBox="0 0 24 24"
   version="1.1"
   id="svg856"
   xmlns="http://www.w3.org/2000/svg">
  <defs
     id="defs853" />
  <g
     id="layer1">
   <path
       d="M -4.8093963e-5,8.1081624 V 9.5620843 H 2.5962411 V 15.89703 H 4.1540145 V 9.5620843 H 6.7503037 V 8.1081624 Z"
       id="path278"/>
    <path
       d="M 7.9082488,15.89703 V 8.1081624 h 3.3959462 q 2.726104,0 2.726104,2.2431936 0,2.326275 -2.720911,2.326275 H 9.4660223 v 3.219399 z m 1.5577735,-4.673321 h 2.1808827 q 0.939857,-0.01558 0.96582,-0.856775 -0.02596,-0.7685016 -0.950242,-0.7996571 H 9.4660223 Z"
       id="path280" />
    <path
       d="M 15.177858,15.89703 V 8.1081624 h 3.395946 q 2.726104,0 2.726104,2.2431936 0,2.326275 -2.720911,2.326275 h -1.843366 v 3.219399 z m 1.557773,-4.673321 h 2.180883 q 0.939857,-0.01558 0.96582,-0.856775 -0.02596,-0.7685016 -0.950242,-0.7996571 h -2.196461 z"
       id="path282"/>
    <path
       d="m 22.447467,8.1029698 h 1.552581 V 15.89703 h -1.552581 z"
       id="path284"/>
  </g>
</svg>
</Blank>
);
