import React from "react";
import { Grommet, ResponsiveContext } from "grommet";
import Content from "./components/Content/Content";
class App extends React.Component {
  theme = {
    global: {
      colors: {
        brand: "#060E17",
      },
      font: {
        family: "Nevis",
        size: "18px",
        height: "20px",
      },
    },
  };
  render() {
    return (
      <Grommet full theme={this.theme}>
        <ResponsiveContext.Consumer>
          {(size) => <Content></Content>}
        </ResponsiveContext.Consumer>
      </Grommet>
    );
  }
}

export default App;
